<template>
  <div class="Examine">
    <div style="position: fixed;top: 55px;right: 10px; z-index: 1000;">
        <div id="wrappers">
          <div class="input-data">
              <input type="text" v-model="text" @keyup.enter="Setquer()" required>
              <div class="underline">
                <i class="el-icon-circle-close" v-show="text.length > 0" style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="text = ''"></i>
                <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search" @click="Setquer()"></el-button>
              </div>
              <label>{{ this.$t('All.单号') }}/{{ this.$t('All.料号') }}/{{ this.$t('All.订单') }}/{{ this.$t('All.品名') }}</label>
          </div>
      </div>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" width="60">
          </el-table-column>
          <el-table-column align="center" prop="Numbers" :label="'IQC' + this.$t('All.单号')" min-width="160"></el-table-column>
          <el-table-column align="center" prop="Date" :label="this.$t('system.创建日期')" width="160"></el-table-column>
          <el-table-column align="center" prop="Order_Number" min-width="100" :label="this.$t('All.订单号')"></el-table-column>
          <el-table-column align="center" prop="Item_No" :label="$t('All.料号')"  min-width="160"></el-table-column>
          <el-table-column align="center" prop="Supplier" :label="this.$t('All.厂商名称')"></el-table-column>
          <el-table-column align="center" prop="Product_name" :label="$t('All.品名')"></el-table-column>
          <el-table-column align="center" prop="Warehousing" :label="this.$t('All.入库数')"></el-table-column>
          <el-table-column align="center" prop="Box" :label="this.$t('All.箱数')"></el-table-column>
          <el-table-column align="center" prop="Boxquantity" :label="this.$t('All.单箱数量')"></el-table-column>
          <el-table-column align="center" prop="Count" :label="this.$t('All.分包几次')"></el-table-column>
          <el-table-column align="center" prop="Operator" :label="this.$t('All.检验员')"></el-table-column>
          <el-table-column align="center" prop="User" :label="this.$t('All.操作员')"></el-table-column>
          <el-table-column align="center" prop="Type" :label="this.$t('All.操作类型')" width="110px">
            <template slot-scope="scope">
              <el-tag>{{ scope.row.Type === 0?$t('All.进料'):scope.row.Type === 2?$t('All.拆包'):scope.row.Type === 3?$t('All.退料'):scope.row.Type === 1?$t('All.补PASS单'):'' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="this.$t('All.状态')" width="120" fixed="right">
            <template slot-scope="scope" >
              <el-tag :type="scope.row.State === 0?'success':scope.row.State === 2?'warning':scope.row.State === 3?'primary':'danger'" effect="dark">{{ scope.row.State === 0?$t('All.有效'):scope.row.State === 2?$t('All.锁') + scope.row.Order_no:scope.row.State === 3?$t('All.完') + scope.row.Order_no:$t('All.失效') }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column  align="center" fixed="right" width="150" :label="this.$t('All.操作类型')">
            <template slot-scope="scope">
              <el-button size="mini" style="text-align: right;" type="primary" :disabled="!(scope.row.State === 0 && ($store.state.Login.Department === 'IQC' || $store.state.Login.Department === 'All'))" @click="chongxindata(scope.row)">{{$t('All.重印')}}</el-button>
              <el-button size="mini" style="text-align: right;" type="danger" :disabled="$store.state.Login.Department !== 'All'" @click="del(scope.row)">Del</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 50, 100, 500]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <el-dialog
      :title="this.$t('All.重新列印') +'/'+ this.$t('All.选择打印机')"
      :visible.sync="dialogVisible"
      width="350px"
      :before-close="handleClose"
      append-to-body>
      <el-form>
        <el-form-item :label="this.$t('All.打印机')">
          <el-select v-model="printer" value-key="Id" :placeholder="this.$t('All.请选择打印机')">
            <el-option
              v-for="item in this.$store.state.PrinterData"
              :key="item.Name"
              :label="item.Wz"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ this.$t('All.取消') }}</el-button>
        <el-button type="primary" @click="chongxin()">{{ this.$t('All.确定') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      text: '',
      dialogVisible: false,
      prindata: '',
      printer: {}
    }
  },
  created () {
    this.TraceQuery()
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData', 'TabComponentFun']),
    async TraceQuery () {
      const intfs = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login,
        Text: this.text
      }
      const { data: res } = await this.$http.post('/api/Trace/TraceQuery', intfs)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.Traceprin
    },
    Setquer () {
      this.pageindex = 1
      this.TraceQuery()
    },
    async chongxindata (row) {
      this.prindata = row
      this.dialogVisible = true
    },
    async chongxin () {
      const loadingInstance = this.$loading({ text: this.$t('All.打印中') + '...', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      this.prindata.UserData = this.$store.state.Login
      this.prindata.Printer = this.printer
      const { data: res } = await this.$http.post('/api/Trace/TraceReprint', this.prindata)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(res.msg)
      }
      this.$message.success(res.msg)
      this.dialogVisible = false
      loadingInstance.close()
    },
    handleSizeChange (val) {
      this.page_size = val
      this.TraceQuery()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.TraceQuery()
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    async del (row) {
      const loadingInstance = this.$loading({ text: this.$t('All.删除中') + '...', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Trace/DelTraceprin', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(res.msg)
      }
      this.$message.success(res.msg)
      this.dialogVisible = false
      this.TraceQuery()
      loadingInstance.close()
    }
  }
}
</script>
<style lang="less" scoped>
  /** talbe表头置顶**/
  /deep/ .el-table {
    overflow: visible;
 }

 /deep/ .el-table__header-wrapper {
    position: sticky;
    top: 0px;
    z-index: 10;
 }
</style>
